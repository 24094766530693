import {
    GET_USERS_REQUEST,
    GET_USERS_RESPONSE,
    GET_USERS_CLEAN,
    GET_USERS_ERROR,
    SET_PASSWORD_CLEAN,
    SET_PASSWORD_ERROR,
    SET_PASSWORD_REQUEST,
    SET_PASSWORD_RESPONSE,
    UPDATE_USER_ERROR,
    UPDATE_USER_CLEAN,
    UPDATE_USER_RESPONSE,
    UPDATE_USER_REQUEST,
    ADD_USER_REQUEST,
    ADD_USER_RESPONSE,
    ADD_USER_ERROR,
    ADD_USER_CLEAN,
} from '../constants/Users';

const initState = {
    loadingUsers: false,
    errorMessage: '',
    usersData: undefined,

    setPasswordBusy: false,
    errorSetPassword: '',
    userSetPassword: undefined,

    updateUserBusy: false,
    errorUpdateUser: '',
    updatedUser: undefined,

    addUserBusy: false,
    errorAddUser: '',
    addedUser: undefined,
};

const users = (state = initState, action) => {
    switch (action.type) {
        case GET_USERS_REQUEST:
            return {
                ...state,
                loadingUsers: true,
            };
        case GET_USERS_RESPONSE:
            return {
                ...state,
                usersData: action.usersData,
                loadingUsers: false,
            };
        case GET_USERS_ERROR:
            return {
                ...state,
                errorMessage: action.errorMessage,
                loadingUsers: false,
            };
        case GET_USERS_CLEAN: {
            return {
                ...state,
                errorMessage: '',
                loadingUsers: false,
            };
        }
        //////SET PASSWORD//////
        case SET_PASSWORD_REQUEST:
            return {
                ...state,
                setPasswordBusy: true,
                userSetPassword: action.userSetPassword,
            };
        case SET_PASSWORD_RESPONSE:
            return {
                ...state,
                setPasswordBusy: false,
                errorSetPassword: '',
            };
        case SET_PASSWORD_ERROR:
            return {
                ...state,
                errorSetPassword: action.error,
                setPasswordBusy: false,
            };
        case SET_PASSWORD_CLEAN: {
            return {
                ...state,
                setPasswordBusy: false,
                errorSetPassword: '',
                userSetPassword: undefined,
            };
        }

        //////UPDATE USER//////
        case UPDATE_USER_REQUEST:
            return {
                ...state,
                updateUserBusy: true,
            };
        case UPDATE_USER_RESPONSE:
            return {
                ...state,
                updateUserBusy: false,
                errorUpdateUser: '',
                updatedUser: action.updatedUser,
            };
        case UPDATE_USER_ERROR:
            return {
                ...state,
                errorUpdateUser: action.error,
                updateUserBusy: false,
            };
        case UPDATE_USER_CLEAN: {
            return {
                ...state,
                updateUserBusy: false,
                errorUpdateUser: '',
                updatedUser: undefined,
            };
        }

        //////ADD USER//////
        case ADD_USER_REQUEST:
            return {
                ...state,
                addUserBusy: true,
            };
        case ADD_USER_RESPONSE:
            return {
                ...state,
                addUserBusy: false,
                errorAddUser: '',
                addedUser: action.addedUser,
            };
        case ADD_USER_ERROR:
            return {
                ...state,
                errorAddUser: action.error,
                addUserBusy: false,
            };
        case ADD_USER_CLEAN: {
            return {
                ...state,
                addUserBusy: false,
                errorAddUser: '',
                addedUser: undefined,
            };
        }

        default:
            return state;
    }
};

export default users;
