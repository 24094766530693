import axios from 'auth/FetchInterceptor';

const USER_URL = '/api/auth/users';
const ROLE_URL = '/api/auth/roles';
const SET_PASSWORD_USER_URL = '/api/auth/setPassword';

const userService = {};

const addQueryParam = (param, url) => {
    url = url.includes('?') ? (url += `&${param}`) : (url += `?${param}`);
    return url;
};

userService.getUsers = async (query) => {
    let url = USER_URL;
    if (query.page) {
        url = addQueryParam(`page=${query.page}`, url);
    }
    if (query.limit) {
        url = addQueryParam(`limit=${query.limit}`, url);
    }
    if (query.sort) {
        url = addQueryParam(`sort=${query.sort}`, url);
    }
    if (query.filters) {
        for (const key in query.filters) {
            if (query.filters.hasOwnProperty(key) && query.filters[key]) {
                url = addQueryParam(`${key}=${query.filters[key]}`, url);
            }
        }
    }

    console.log(url);

    return axios.get(url);
};

userService.setUserPassword = async (userId, password, confirmPassword) => {
    let url = SET_PASSWORD_USER_URL;
    return axios.post(`${url}/${userId}`, { password, confirmPassword });
};

userService.updateUser = async (userId, firstName, lastName, roles, isVerified) => {
    let url = USER_URL;
    return axios.put(`${url}/${userId}`, { firstName, lastName, roles, isVerified });
};

userService.addUser = async (firstName, lastName, email, password, roles) => {
    let url = USER_URL;
    return axios.post(`${url}`, { firstName, lastName, email, roles, password });
};

userService.usersTotalStats = async (id) => {
    let url = `${USER_URL}/stats/totals`;
    console.log(url);
    return axios.get(url);
};

userService.countUsersByMonth = async (id) => {
    let url = `${USER_URL}/stats/usersMonths`;
    console.log(url);
    return axios.get(url);
};

userService.lastRegisteredUsers = async (id) => {
    let url = `${USER_URL}/stats/lastSignUp`;
    console.log(url);
    return axios.get(url);
};

userService.getRoles = async () => {
    let url = `${ROLE_URL}`;
    console.log(url);
    return axios.get(url);
};

export default userService;
