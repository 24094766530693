import fetch from 'auth/FetchInterceptor';

const LOGIN = '/api/auth/login';
const LOGOUT = '/api/auth/logout';
const ME = '/api/auth/me';

const JwtAuthService = {};

JwtAuthService.login = function (data) {
    return fetch({
        url: LOGIN,
        method: 'post',
        headers: {
            'public-request': 'true',
        },
        data: data,
    });
};

JwtAuthService.logout = function (data) {
    return fetch({
        url: LOGOUT,
        method: 'delete',
        headers: {
            'public-request': 'true',
        },
        data: data,
    });
};

JwtAuthService.signUp = function (data) {
    return fetch({
        url: '/auth/signup',
        method: 'post',
        headers: {
            'public-request': 'true',
        },
        data: data,
    });
};

JwtAuthService.me = function (data) {
    return fetch({
        url: ME,
        method: 'get',
    });
};

export default JwtAuthService;
