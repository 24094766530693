import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { connect } from "react-redux";
import { onMobileNavToggle } from 'redux/actions/Theme';
import Utils from "utils";

export const AppViews = (props) => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        {
          Utils.isAdministrator(props.user) &&
          <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
        }
        {/* ////////////PLACES///////////////// */}
        <Route path={`${APP_PREFIX_PATH}/places/add`} component={lazy(() => import(`./places/AddPlace`))} />
        <Route path={`${APP_PREFIX_PATH}/places/edit/:id`} component={lazy(() => import(`./places/EditPlace`))} />
        <Route path={`${APP_PREFIX_PATH}/places/:id`} component={lazy(() => import(`./places/PlaceDetails`))} />
        <Route path={`${APP_PREFIX_PATH}/places`} component={lazy(() => import(`./places`))} />    
        {/* ////////////USERS///////////////// */}
        {/* <Route path={`${APP_PREFIX_PATH}/users/add`} component={lazy(() => import(`./users/AddUser`))} /> */}
        {/* <Route path={`${APP_PREFIX_PATH}/users/edit/:id`} component={lazy(() => import(`./users/EditUser`))} /> */}
        {/* <Route path={`${APP_PREFIX_PATH}/users/:id`} component={lazy(() => import(`./users/UserDetails`))} /> */}
        {
          Utils.isAdministrator(props.user) &&
          <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users`))} />      
        }

        <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
        <Route path={`${APP_PREFIX_PATH}/components`} component={lazy(() => import(`./components`))} />
        <Route path={`${APP_PREFIX_PATH}/pages`} component={lazy(() => import(`./pages`))} />
        <Route path={`${APP_PREFIX_PATH}/maps`} component={lazy(() => import(`./maps`))} />
        <Route path={`${APP_PREFIX_PATH}/charts`} component={lazy(() => import(`./charts`))} />
        <Route path={`${APP_PREFIX_PATH}/docs`} component={lazy(() => import(`./docs`))} />
        {
           Utils.isAdministrator(props.user) ? (
          <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />)
          :
          (<Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/places`} />)
        }
      </Switch>
    </Suspense>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { user } = auth;
  return { user };
};
export default connect(mapStateToProps, { onMobileNavToggle })(React.memo(AppViews));
//export default React.memo(AppViews);
