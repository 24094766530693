import {
    GET_USERS_REQUEST,
    GET_USERS_RESPONSE,
    GET_USERS_CLEAN,
    GET_USERS_ERROR,
    SET_PASSWORD_CLEAN,
    SET_PASSWORD_ERROR,
    SET_PASSWORD_REQUEST,
    SET_PASSWORD_RESPONSE,
    UPDATE_USER_ERROR,
    UPDATE_USER_CLEAN,
    UPDATE_USER_RESPONSE,
    UPDATE_USER_REQUEST,
    ADD_USER_REQUEST,
    ADD_USER_RESPONSE,
    ADD_USER_ERROR,
    ADD_USER_CLEAN,
} from '../constants/Users';

export const getUsers = (query) => {
    return {
        type: GET_USERS_REQUEST,
        payload: query,
    };
};

export const getUsersResponse = (usersData) => {
    return {
        type: GET_USERS_RESPONSE,
        usersData,
    };
};

export const getUsersError = (errorMessage) => {
    return {
        type: GET_USERS_ERROR,
        errorMessage,
    };
};

export const getUsersClean = () => {
    return {
        type: GET_USERS_CLEAN,
    };
};

/////////////////SET PASSWORD/////////////
export const setUserPassword = (userSetPassword, password, confirmPassword) => {
    return {
        type: SET_PASSWORD_REQUEST,
        userSetPassword,
        password,
        confirmPassword,
    };
};

export const setUserPasswordResponse = () => {
    return {
        type: SET_PASSWORD_RESPONSE,
    };
};

export const setUserPasswordError = (error) => {
    return {
        type: SET_PASSWORD_ERROR,
        error,
    };
};

export const setUserPasswordClean = () => {
    return {
        type: SET_PASSWORD_CLEAN,
    };
};

/////////////////UPDATE USER/////////////
export const updateUserRequest = (userData) => {
    return {
        type: UPDATE_USER_REQUEST,
        userData,
    };
};

export const updateUserResponse = (updatedUser) => {
    return {
        type: UPDATE_USER_RESPONSE,
        updatedUser,
    };
};

export const updateUserError = (error) => {
    return {
        type: UPDATE_USER_ERROR,
        error,
    };
};

export const updateUserClean = () => {
    return {
        type: UPDATE_USER_CLEAN,
    };
};

/////////////////ADD USER/////////////
export const addUserRequest = (userData) => {
    return {
        type: ADD_USER_REQUEST,
        userData,
    };
};

export const addUserResponse = (addedUser) => {
    return {
        type: ADD_USER_RESPONSE,
        addedUser,
    };
};

export const addUserError = (error) => {
    return {
        type: ADD_USER_ERROR,
        error,
    };
};

export const addUserClean = () => {
    return {
        type: ADD_USER_CLEAN,
    };
};
