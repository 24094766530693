import { put, call, takeLatest } from 'redux-saga/effects';
import { safeWrapper } from 'utils/sagaUtil';
import {
    GET_PLACES_REQUEST,
    DELETE_PLACE_REQUEST,
    GET_PLACE_REQUEST,
    ADD_PLACE_REQUEST,
    UPDATE_PLACE_REQUEST,
    ADD_CATEGORY_REQUEST,
} from '../constants/Places';
import {
    getPlacesError,
    getPlacesResponse,
    deletePlaceError,
    deletePlaceResponse,
    getPlaceResponse,
    getPlaceError,
    addPlaceError,
    addPlaceResponse,
    updatePlaceError,
    updatePlaceResponse,
    addCategoryError,
    addCategoryResponse,
} from '../actions/Places';

import PlaceService from 'services/PlaceService';

function* getPlaces({ payload }) {
    const response = yield call(PlaceService.getPlaces, payload);
    yield put(getPlacesResponse(response));
}

function* deletePlace({ id }) {
    yield call(PlaceService.deletePlace, id);
    yield put(deletePlaceResponse(id));
}

function* getPlace({ id }) {
    const response = yield call(PlaceService.getPlace, id);
    yield put(getPlaceResponse(response));
}

function* addPlace({ formData }) {
    const response = yield call(PlaceService.addPlace, formData);
    yield put(addPlaceResponse(response));
}

function* updatePlace({ formData }) {
    const response = yield call(PlaceService.updatePlace, formData);
    yield put(updatePlaceResponse(response));
}

function* addCategory({ name }) {
    const response = yield call(PlaceService.addCategory, name);
    yield put(addCategoryResponse(response));
}

export default function* rootSaga() {
    //yield all([fork(getPlaces)]);
    yield takeLatest(
        GET_PLACES_REQUEST,
        safeWrapper(function* (error) {
            yield put(getPlacesError(error.stack));
        }, getPlaces),
    );
    yield takeLatest(
        DELETE_PLACE_REQUEST,
        safeWrapper(function* (error) {
            yield put(deletePlaceError(error));
        }, deletePlace),
    );
    yield takeLatest(
        GET_PLACE_REQUEST,
        safeWrapper(function* (error) {
            yield put(getPlaceError(error.stack));
        }, getPlace),
    );

    yield takeLatest(
        ADD_PLACE_REQUEST,
        safeWrapper(function* (error) {
            yield put(addPlaceError(error));
        }, addPlace),
    );

    yield takeLatest(
        UPDATE_PLACE_REQUEST,
        safeWrapper(function* (error) {
            yield put(updatePlaceError(error));
        }, updatePlace),
    );

    yield takeLatest(
        ADD_CATEGORY_REQUEST,
        safeWrapper(function* (error) {
            yield put(addCategoryError(error));
        }, addCategory),
    );
}
