import antdItIT from 'antd/es/locale/it_IT';
import enMsg from '../locales/it_IT.json';

const ITLang = {
    antd: antdItIT,
    locale: 'it-IT',
    messages: {
        ...enMsg,
    },
};
export default ITLang;
