export const GET_PLACES_REQUEST = 'GET_PLACES_REQUEST';
export const GET_PLACES_RESPONSE = 'GET_PLACES_RESPONSE';
export const GET_PLACES_ERROR = 'GET_PLACES_ERROR';
export const GET_PLACES_CLEAN = 'GET_PLACES_CLEAN';

export const DELETE_PLACE_REQUEST = 'DELETE_PLACE_REQUEST';
export const DELETE_PLACE_RESPONSE = 'DELETE_PLACE_RESPONSE';
export const DELETE_PLACE_ERROR = 'DELETE_PLACE_ERROR';
export const DELETE_PLACE_CLEAN = 'DELETE_PLACE_CLEAN';

export const GET_PLACE_REQUEST = 'GET_PLACE_REQUEST';
export const GET_PLACE_RESPONSE = 'GET_PLACE_RESPONSE';
export const GET_PLACE_ERROR = 'GET_PLACE_ERROR';
export const GET_PLACE_CLEAN = 'GET_PLACE_CLEAN';

export const ADD_PLACE_REQUEST = 'ADD_PLACE_REQUEST';
export const ADD_PLACE_RESPONSE = 'ADD_PLACE_RESPONSE';
export const ADD_PLACE_ERROR = 'ADD_PLACE_ERROR';
export const ADD_PLACE_CLEAN = 'ADD_PLACE_CLEAN';

export const UPDATE_PLACE_REQUEST = 'UPDATE_PLACE_REQUEST';
export const UPDATE_PLACE_RESPONSE = 'UPDATE_PLACE_RESPONSE';
export const UPDATE_PLACE_ERROR = 'UPDATE_PLACE_ERROR';
export const UPDATE_PLACE_CLEAN = 'UPDATE_PLACE_CLEAN';

export const ADD_CATEGORY_REQUEST = 'ADD_CATEGORY_REQUEST';
export const ADD_CATEGORY_RESPONSE = 'ADD_CATEGORY_RESPONSE';
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR';
export const ADD_CATEGORY_CLEAN = 'ADD_CATEGORY_CLEAN';

export const SET_MY_PLACES = 'SET_MY_PLACES';
