const dev = {
    API_ENDPOINT_URL: 'https://webapp.flagperti.it:3005',
};

const prod = {
    API_ENDPOINT_URL: 'https://webapp.flagperti.it:3005',
};

const test = {
    API_ENDPOINT_URL: 'https://webapp.flagperti.it:3005',
};

const getEnv = () => {
    switch (process.env.NODE_ENV) {
        case 'development':
            return dev;
        case 'production':
            return prod;
        case 'test':
            return test;
        default:
            break;
    }
};

export const env = getEnv();
