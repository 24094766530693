import {
    DELETE_PLACE_CLEAN,
    DELETE_PLACE_ERROR,
    DELETE_PLACE_REQUEST,
    DELETE_PLACE_RESPONSE,
    GET_PLACES_CLEAN,
    GET_PLACES_ERROR,
    GET_PLACES_REQUEST,
    GET_PLACES_RESPONSE,
    GET_PLACE_CLEAN,
    GET_PLACE_ERROR,
    GET_PLACE_REQUEST,
    GET_PLACE_RESPONSE,
    ADD_PLACE_CLEAN,
    ADD_PLACE_ERROR,
    ADD_PLACE_REQUEST,
    ADD_PLACE_RESPONSE,
    UPDATE_PLACE_REQUEST,
    UPDATE_PLACE_ERROR,
    UPDATE_PLACE_RESPONSE,
    UPDATE_PLACE_CLEAN,
    SET_MY_PLACES,
    ADD_CATEGORY_REQUEST,
    ADD_CATEGORY_RESPONSE,
    ADD_CATEGORY_ERROR,
    ADD_CATEGORY_CLEAN,
} from '../constants/Places';

export const getPlaces = (query) => {
    return {
        type: GET_PLACES_REQUEST,
        payload: query,
    };
};

export const getPlacesResponse = (placesData) => {
    return {
        type: GET_PLACES_RESPONSE,
        placesData,
    };
};

export const getPlacesError = (errorMessage) => {
    return {
        type: GET_PLACES_ERROR,
        errorMessage,
    };
};

export const getPlacesClean = () => {
    return {
        type: GET_PLACES_CLEAN,
    };
};

export const deletePlace = (id) => {
    return {
        type: DELETE_PLACE_REQUEST,
        id,
    };
};

export const deletePlaceError = (errorMessage) => {
    return {
        type: DELETE_PLACE_ERROR,
        errorMessage,
    };
};

export const deletePlaceResponse = (id) => {
    return {
        type: DELETE_PLACE_RESPONSE,
        id,
    };
};

export const deletePlaceClean = () => {
    return {
        type: DELETE_PLACE_CLEAN,
    };
};

//////////////GET PLACE//////////
export const getPlace = (id) => {
    return {
        type: GET_PLACE_REQUEST,
        id,
    };
};

export const getPlaceError = (errorMessage) => {
    return {
        type: GET_PLACE_ERROR,
        errorMessage,
    };
};

export const getPlaceResponse = (place) => {
    return {
        type: GET_PLACE_RESPONSE,
        place,
    };
};

export const getPlaceClean = () => {
    return {
        type: GET_PLACE_CLEAN,
    };
};

//////////ADD PLACE////////////////
export const addPlace = (formData) => {
    return {
        type: ADD_PLACE_REQUEST,
        formData,
    };
};

export const addPlaceError = (errorMessage) => {
    return {
        type: ADD_PLACE_ERROR,
        errorMessage,
    };
};

export const addPlaceResponse = (place) => {
    return {
        type: ADD_PLACE_RESPONSE,
        place,
    };
};

export const addPlaceClean = () => {
    return {
        type: ADD_PLACE_CLEAN,
    };
};

//////////UPDATE PLACE////////////////
export const updatePlace = (formData) => {
    return {
        type: UPDATE_PLACE_REQUEST,
        formData,
    };
};

export const updatePlaceError = (errorMessage) => {
    return {
        type: UPDATE_PLACE_ERROR,
        errorMessage,
    };
};

export const updatePlaceResponse = (place) => {
    return {
        type: UPDATE_PLACE_RESPONSE,
        place,
    };
};

export const updatePlaceClean = () => {
    return {
        type: UPDATE_PLACE_CLEAN,
    };
};

export const setMyPlaces = (myPlaces) => {
    return {
        type: SET_MY_PLACES,
        myPlaces,
    };
};

//////////ADD CATEGORY////////////////
export const addCategory = (name) => {
    return {
        type: ADD_CATEGORY_REQUEST,
        name,
    };
};

export const addCategoryError = (errorMessage) => {
    return {
        type: ADD_CATEGORY_ERROR,
        errorMessage,
    };
};

export const addCategoryResponse = (category) => {
    return {
        type: ADD_CATEGORY_RESPONSE,
        category,
    };
};

export const addCategoryClean = () => {
    return {
        type: ADD_CATEGORY_CLEAN,
    };
};
