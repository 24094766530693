import { put, call, takeLatest } from 'redux-saga/effects';
import { safeWrapper } from 'utils/sagaUtil';
import {
    ADD_USER_REQUEST,
    GET_USERS_REQUEST,
    SET_PASSWORD_REQUEST,
    UPDATE_USER_REQUEST,
} from '../constants/Users';
import {
    getUsersError,
    getUsersResponse,
    setUserPasswordResponse,
    setUserPasswordError,
    updateUserResponse,
    updateUserError,
    addUserError,
    addUserResponse,
} from '../actions/Users';
import UserService from 'services/UserService';

function* getUsers({ payload }) {
    const response = yield call(UserService.getUsers, payload);
    yield put(getUsersResponse(response));
}

function* setUserPassword({ userSetPassword, password, confirmPassword }) {
    yield call(UserService.setUserPassword, userSetPassword, password, confirmPassword);
    yield put(setUserPasswordResponse());
}

function* updateUser({ userData }) {
    const response = yield call(
        UserService.updateUser,
        userData.id,
        userData.firstName,
        userData.lastName,
        userData.roles,
        userData.isVerified,
    );
    yield put(updateUserResponse(response));
}

function* addUser({ userData }) {
    const response = yield call(
        UserService.addUser,
        userData.firstName,
        userData.lastName,
        userData.email,
        userData.password,
        userData.roles,
    );
    yield put(addUserResponse(response.data.user));
}

export default function* rootSaga() {
    yield takeLatest(
        GET_USERS_REQUEST,
        safeWrapper(function* (error) {
            yield put(getUsersError(error.stack));
        }, getUsers),
    );
    yield takeLatest(
        SET_PASSWORD_REQUEST,
        safeWrapper(function* (error) {
            yield put(setUserPasswordError(error));
        }, setUserPassword),
    );

    yield takeLatest(
        UPDATE_USER_REQUEST,
        safeWrapper(function* (error) {
            yield put(updateUserError(error));
        }, updateUser),
    );

    yield takeLatest(
        ADD_USER_REQUEST,
        safeWrapper(function* (error) {
            yield put(addUserError(error));
        }, addUser),
    );
}
