import {
    DELETE_PLACE_CLEAN,
    DELETE_PLACE_ERROR,
    DELETE_PLACE_REQUEST,
    DELETE_PLACE_RESPONSE,
    GET_PLACES_CLEAN,
    GET_PLACES_ERROR,
    GET_PLACES_REQUEST,
    GET_PLACES_RESPONSE,
    GET_PLACE_CLEAN,
    GET_PLACE_ERROR,
    GET_PLACE_REQUEST,
    GET_PLACE_RESPONSE,
    ADD_PLACE_CLEAN,
    ADD_PLACE_ERROR,
    ADD_PLACE_REQUEST,
    ADD_PLACE_RESPONSE,
    UPDATE_PLACE_REQUEST,
    UPDATE_PLACE_RESPONSE,
    UPDATE_PLACE_ERROR,
    UPDATE_PLACE_CLEAN,
    SET_MY_PLACES,
    ADD_CATEGORY_REQUEST,
    ADD_CATEGORY_RESPONSE,
    ADD_CATEGORY_ERROR,
    ADD_CATEGORY_CLEAN,
} from '../constants/Places';

const initState = {
    loadingPlaces: false,
    errorMessage: '',
    placesData: undefined,

    deletePlaceId: undefined,
    deletingPlace: false,
    errorMessageDelete: undefined,

    updatedPlace: undefined,
    updateErrorMessage: undefined,
    updatingPlace: false,

    requestedPlace: undefined,
    errorMessageGet: undefined,
    gettingPlace: false,

    addedPlace: undefined,
    errorMessageAdd: undefined,
    addingPlace: false,

    addedCategory: undefined,
    errorMessageAddCategory: undefined,
    addingCategory: false,

    isMyPlaces: true,
};

const places = (state = initState, action) => {
    switch (action.type) {
        case GET_PLACES_REQUEST:
            return {
                ...state,
                loadingPlaces: true,
            };
        case GET_PLACES_RESPONSE:
            return {
                ...state,
                placesData: action.placesData,
                loadingPlaces: false,
            };
        case GET_PLACES_ERROR:
            return {
                ...state,
                errorMessage: action.errorMessage,
                loadingPlaces: false,
            };
        case GET_PLACES_CLEAN: {
            return {
                ...state,
                errorMessage: '',
                loadingUsers: false,
            };
        }
        //DELETE////////////
        case DELETE_PLACE_REQUEST:
            return {
                ...state,
                deletePlaceId: action.id,
                deletingPlace: true,
            };
        case DELETE_PLACE_RESPONSE:
            return {
                ...state,
                deletePlaceId: action.id,
                deletingPlace: false,
            };
        case DELETE_PLACE_ERROR:
            return {
                ...state,
                errorMessageDelete: action.errorMessage,
                deletingPlace: false,
            };
        case DELETE_PLACE_CLEAN: {
            return {
                ...state,
                errorMessageDelete: '',
                deletingPlace: false,
                deletePlaceId: undefined,
            };
        }

        //GET PLACE////////////
        case GET_PLACE_REQUEST:
            return {
                ...state,
                requestedPlace: undefined,
                gettingPlace: true,
            };
        case GET_PLACE_RESPONSE:
            return {
                ...state,
                requestedPlace: action.place,
                gettingPlace: false,
            };
        case GET_PLACE_ERROR:
            return {
                ...state,
                errorMessageGet: action.errorMessage,
                gettingPlace: false,
            };
        case GET_PLACE_CLEAN: {
            return {
                ...state,
                requestedPlace: undefined,
                errorMessageGet: undefined,
                gettingPlace: false,
            };
        }
        ///////ADD PLACES///////////
        case ADD_PLACE_REQUEST:
            return {
                ...state,
                addedPlace: undefined,
                addingPlace: true,
            };
        case ADD_PLACE_RESPONSE:
            return {
                ...state,
                addedPlace: action.place,
                addingPlace: false,
            };
        case ADD_PLACE_ERROR:
            return {
                ...state,
                errorMessageAdd: action.errorMessage,
                addingPlace: false,
            };
        case ADD_PLACE_CLEAN: {
            return {
                ...state,
                addedPlace: undefined,
                errorMessageAdd: undefined,
                addingPlace: false,
            };
        }
        ///////UPDATE PLACES///////////
        case UPDATE_PLACE_REQUEST:
            return {
                ...state,
                updatedPlace: undefined,
                updatingPlace: true,
            };
        case UPDATE_PLACE_RESPONSE:
            return {
                ...state,
                updatedPlace: action.place,
                updatingPlace: false,
            };
        case UPDATE_PLACE_ERROR:
            return {
                ...state,
                updateErrorMessage: action.errorMessage,
                updatingPlace: false,
            };
        case UPDATE_PLACE_CLEAN: {
            return {
                ...state,
                updatedPlace: undefined,
                updateErrorMessage: undefined,
                updatingPlace: false,
            };
        }
        case SET_MY_PLACES: {
            return {
                ...state,
                isMyPlaces: action.myPlaces,
            };
        }

        ///////ADD CATEGORY///////////
        case ADD_CATEGORY_REQUEST:
            return {
                ...state,
                addedCategory: undefined,
                addingCategory: true,
            };
        case ADD_CATEGORY_RESPONSE:
            return {
                ...state,
                addedCategory: action.category,
                errorMessageAddCategory: undefined,
                addingCategory: false,
            };
        case ADD_CATEGORY_ERROR:
            return {
                ...state,
                errorMessageAddCategory: action.errorMessage,
                addingCategory: false,
            };
        case ADD_CATEGORY_CLEAN: {
            return {
                ...state,
                addedCategory: undefined,
                errorMessageAddCategory: undefined,
                addingCategory: false,
            };
        }
        default:
            return state;
    }
};

export default places;
