export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_RESPONSE = 'GET_USERS_RESPONSE';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';
export const GET_USERS_CLEAN = 'GET_USERS_CLEAN';

export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST';
export const SET_PASSWORD_RESPONSE = 'SET_PASSWORD_RESPONSE';
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR';
export const SET_PASSWORD_CLEAN = 'SET_PASSWORD_CLEAN';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_RESPONSE = 'UPDATE_USER_RESPONSE';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const UPDATE_USER_CLEAN = 'UPDATE_USER_CLEAN';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_RESPONSE = 'ADD_USER_RESPONSE';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';
export const ADD_USER_CLEAN = 'ADD_USER_CLEAN';

export const ADD_PLACE_REQUEST = 'ADD_PLACE_REQUEST';
export const ADD_PLACE_RESPONSE = 'ADD_PLACE_RESPONSE';
export const ADD_PLACE_ERROR = 'ADD_PLACE_ERROR';
export const ADD_PLACE_CLEAN = 'ADD_PLACE_CLEAN';

export const UPDATE_PLACE_REQUEST = 'UPDATE_PLACE_REQUEST';
export const UPDATE_PLACE_RESPONSE = 'UPDATE_PLACE_RESPONSE';
export const UPDATE_PLACE_ERROR = 'UPDATE_PLACE_ERROR';
export const UPDATE_PLACE_CLEAN = 'UPDATE_PLACE_CLEAN';

export const SET_MY_USERS = 'SET_MY_USERS';
