import axios from 'auth/FetchInterceptor';
import { API_BASE_URL } from 'configs/AppConfig';

const PLACE_URL = '/api/v1/places';
const REVIEW_URL = '/api/v1/reviews';
export const PLACE_RESOURCE_URL = `${API_BASE_URL}/api/v1/places/resource`;

const exampleService = {};

const addQueryParam = (param, url) => {
    url = url.includes('?') ? (url += `&${param}`) : (url += `?${param}`);
    return url;
};

exampleService.getPlaces = async (query) => {
    let url = PLACE_URL;
    if (query.page) {
        url = addQueryParam(`page=${query.page}`, url);
    }
    if (query.limit) {
        url = addQueryParam(`limit=${query.limit}`, url);
    }
    if (query.sort) {
        url = addQueryParam(`sort=${query.sort}`, url);
    }
    if (query.filters) {
        for (const key in query.filters) {
            if (query.filters.hasOwnProperty(key) && query.filters[key]) {
                url = addQueryParam(`${key}=${query.filters[key]}`, url);
            }
        }
    }

    console.log(url);

    return axios.get(url);
};

exampleService.deletePlace = async (id) => {
    let url = `${PLACE_URL}/${id}`;
    console.log(url);
    return axios.delete(url);
};

exampleService.getPlace = async (id) => {
    let url = `${PLACE_URL}/${id}`;
    console.log(url);
    return axios.get(url);
};

exampleService.addPlace = async (formData) => {
    let url = `${PLACE_URL}`;
    console.log(url);
    return axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

exampleService.updatePlace = async (formData) => {
    let url = `${PLACE_URL}`;
    console.log(url);
    return axios.put(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

exampleService.placeTotalStats = async (id) => {
    let url = `${PLACE_URL}/stats/totals`;
    console.log(url);
    return axios.get(url);
};

exampleService.reviewsTotalStats = async (id) => {
    let url = `${REVIEW_URL}/stats/totals`;
    console.log(url);
    return axios.get(url);
};

exampleService.lastReviews = async (id) => {
    let url = `${REVIEW_URL}?limit=10&sort=createdAt_DESC`;
    console.log(url);
    return axios.get(url);
};

exampleService.getCategories = async (id) => {
    let url = `${PLACE_URL}/categories`;
    console.log(url);
    return axios.get(url);
};

exampleService.getProvinces = async (id) => {
    let url = `${PLACE_URL}/provinces`;
    console.log(url);
    return axios.get(url);
};

exampleService.addCategory = async (name) => {
    let url = `${PLACE_URL}/categories`;
    console.log(url);
    return axios.post(url, { name });
};

export default exampleService;
